export const CUSTOM_THEMES = {
    tmc: {
        mainColor: '#1F2354',
        hoverColor: "#227F19",
        secondaryColor: '#f6f9fc',
        sidebarColor: '#f2f6fb',
        sidebarLinkHover: '#EBF0F8',
        sidebarLinkSelection: '#EBF0F8',
        fontFamily: 'BR Firma',
        sidebarBG: '#F2F6FC'
    },
    redTech: {
        mainColor: '#9E251C',
        hoverColor: "#9E251C99",
        secondaryColor: '#f6f9fc',
        sidebarColor: '#f2f6fb',
        sidebarLinkHover: '#EBF0F8',
        sidebarLinkSelection: '#ffffff',
        fontFamily: 'Space Grotesk',
        sidebarBG: '#FAFAFA'
    }
}