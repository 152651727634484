import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { CUSTOM_THEMES } from "../../common/constants/customThemes";
import { landingImage1, landingImage2 } from "../../common/assets/images";
import "./index.css";

function Login() {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  if (keycloak.authenticated) {
    navigate("/");
  }

  useEffect(() => {
    pageLoadEvent("welcome_page");

    const imgElement = imgRef.current;
    if (imgElement) {
      const handleLoad = () => setLoaded(true);

      if (imgElement.complete) {
        handleLoad();
      } else {
        imgElement.addEventListener("load", handleLoad);
        return () => {
          imgElement.removeEventListener("load", handleLoad);
        };
      }
    }
  }, []);

  const gaEvent = () => {
    pageLoadEvent("login_page");
    keycloak.login();
  };

  let landingImageUrl = "";

  if (parent !== window) {
    document.documentElement.style.setProperty(
      "--font-main",
      CUSTOM_THEMES.redTech.fontFamily
    );
    document.documentElement.style.setProperty(
      "--main-color",
      CUSTOM_THEMES.redTech.mainColor
    );
    document.documentElement.style.setProperty(
      "--hover-color",
      CUSTOM_THEMES.redTech.hoverColor
    );
    document.documentElement.style.setProperty(
      "--sidebar-selection-bg-color",
      CUSTOM_THEMES.redTech.sidebarLinkSelection
    );
    document.documentElement.style.setProperty(
      "--sidebar-selection-bg-color",
      CUSTOM_THEMES.redTech.sidebarLinkSelection
    );
    document.documentElement.style.setProperty(
      "--sidebar-bg",
      CUSTOM_THEMES.redTech.sidebarBG
    );
    landingImageUrl = landingImage2;
  } else {
    document.documentElement.style.setProperty(
      "--font-main",
      CUSTOM_THEMES.tmc.fontFamily
    );
    document.documentElement.style.setProperty(
      "--main-color",
      CUSTOM_THEMES.tmc.mainColor
    );
    document.documentElement.style.setProperty(
      "--hover-color",
      CUSTOM_THEMES.tmc.hoverColor
    );
    document.documentElement.style.setProperty(
      "--sidebar-selection-bg-color",
      CUSTOM_THEMES.tmc.sidebarLinkSelection
    );
    document.documentElement.style.setProperty(
      "--sidebar-selection-bg-color",
      CUSTOM_THEMES.tmc.sidebarLinkSelection
    );
    document.documentElement.style.setProperty(
      "--sidebar-bg",
      CUSTOM_THEMES.tmc.sidebarBG
    );
    landingImageUrl = landingImage1;
  }

  return (
    <div className="App">
      <div className="h-screen w-full flex max-h-screen">
        <div className="w-1/2 px-24 flex flex-col justify-center">
          <div className="my-6">
            <div className="text-4xl	text-navy-50 font-bold mb-0">
              Welcome to Marketing Cloud
            </div>
            <p className="text-base	text-gray-90 my-4 mb-8">
              To begin, please click on the <strong> Get Started </strong>{" "}
              button below.
            </p>
          </div>
          <button
            className="h-12 w-60 mt-4 my-2.5 pb-2.5 pt-3 bg-blue-75 text-white text-sm font-semibold rounded-md shrink-0"
            onClick={() => gaEvent()}
          >
            Get Started
          </button>
        </div>

        <div className="relative w-1/2 h-screen pointer-events-none">
          <div
            className={`blurred-img ${
              landingImageUrl === landingImage1
                ? "blurred-img1"
                : "blurred-img2"
            } absolute inset-0 w-full h-full ${loaded ? "loaded" : ""}`}
          ></div>
          <img
            ref={imgRef}
            src={landingImageUrl}
            className={`main-img absolute inset-0 h-full w-full object-cover transition-opacity duration-250 ease-in-out ${
              loaded ? "opacity-100" : "opacity-0"
            }`}
            loading="lazy"
            alt="Landing Image"
          />
        </div>
      </div>
    </div>
  );
}
export default Login;
