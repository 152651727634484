import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import loaderStore, {
  persistor,
} from "./redux-loader/loader-store/loaderStore";
import "./root.component.css";
import keycloak from "./Keycloak";
import PrivateRoute from "./common/utils/PrivateRoute";

const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));

function Root() {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <Provider store={loaderStore}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Suspense fallback="">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <Routes>
                          <Route path="*" element={<Home />} />
                        </Routes>
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ReactKeycloakProvider>
    </div>
  );
}
export default Root;
