import Keycloak from 'keycloak-js';
import env from "../env.json"


/** keycloak configuration for staging env */
/* uncomment this to run keycloak on staging */
const keycloak = new Keycloak(parent === window ? {
  url: env.KEYCLOAK_URL,
  realm: env.KEYCLOAK_REALM,
  clientId: env.KEYCLOAK_CLIENT_ID,
} : {
  url: env.KEYCLOAK_URL,
  realm: env.KEYCLOAK_REALM,
  clientId: env.CLIENT_ID === 'mc-client-staging' ? 'demo_redtech' : 'demo_redtech',
});
export default keycloak;
